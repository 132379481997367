import { Component, Vue } from "vue-property-decorator"

export const defaultListQuery = {
	page: 1,
	page_size: 20,
	ordering: "-id",
}

@Component
export default class ListMixin extends Vue {
	list: any[] = []
	loading = false
	count = 0
	listQuery: any = Object.assign({}, defaultListQuery)
	protected getList: ((params: any) => any) | null = null

	async load() {
		if (this.getList === null) {
			console.error(`getList() for ${this.$options.name} not implemented`)
			return
		}
		this.loading = true
		const { data } = await this.getList(this.listQuery)
		this.setData(data)
		this.loading = false
	}

	setData(data: { results: any[]; count: number }) {
		this.list = data.results
		this.count = data.count
	}
}
