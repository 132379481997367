import { InventoryData } from "@/select/types"
import { ListParams } from "./api/base"
import Generate from "./api/crud"
import { Lookup } from "./base"

export interface Params extends ListParams {
	name?: string
}

export interface Agreement {
	id?: number
	agency?: Lookup | number
	contexts: Lookup[] | number[]
	cpm: number
	devices: number[]
	formats: number[]
	inventory?: InventoryData | number
	status: number
	advertiser: Lookup | number | null
	name: string
}

export interface AgreementForm extends Omit<Agreement, "id"> {
	agency: number
	inventory: number
	advertiser: number | null
	contexts: number[]
}

export interface AgreementData extends Agreement {
	id: number
	agency: Lookup
	inventory: InventoryData
	advertiser: Lookup | null
	contexts: Lookup[]
}

export const defaultAgreementData: Agreement = {
	contexts: [],
	cpm: 0,
	devices: [],
	formats: [],
	status: 1,
	advertiser: null,
	name: "",
}

export default Generate<AgreementForm, AgreementData>("/agreements")
