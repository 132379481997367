






























































import { ref as _ref } from "@vue/composition-api";
import { defaultListQuery } from "@/mixins";
import Pagination from "@/components/Pagination/index.vue";
import { AgreementStatus, Device, formatDisplay } from "@/select";
import Agencies from "@/select2/agency";
import Agreements, { AgreementData } from "@/select2/agreement";
import { statusDisplay } from "@/select/agreement";
import { asCurrency, range } from "@/utils";
import router from "@/router";
import { toHtml } from "@/select2/api/base";
import { Message } from "element-ui";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  function statusType(s: AgreementStatus) {
    if (s === AgreementStatus.Approved) return "success";
    if (s === AgreementStatus.Ended) return "info";
    return "";
  }

  const statuses = range(1, 5).map(n => ({
    value: n,
    text: statusDisplay(n)
  }));
  const isAdmin = User.isAdmin;

  let count = _ref(0);

  let listQuery = _ref({ ...defaultListQuery,
    query: ""
  });

  let loading = _ref(true);

  let agreements = _ref(([] as AgreementData[]));

  let agencies: Lookup[] = _ref([]);

  function filterHandler(filters) {
    listQuery.value = { ...filters,
      page: 1
    };
    load();
  }

  async function load() {
    const data = await Agreements.list(listQuery.value);
    agreements.value = data.results.map(a => ({ ...a,
      statusText: statusDisplay(a.status),
      statusType: statusType(a.status),
      cpm: asCurrency(a.cpm),
      display: `${a.formats.map(f => formatDisplay(f)).join(", ")} - ${a.devices.map(d => Device[d]).join(", ")}`,
      submitting: false
    }));
    count.value = data.count;
    loading.value = false;
  }

  onMounted(async () => {
    if (isAdmin) {
      agencies.value = (await Agencies.list({
        lookup: "1"
      })).map(a => ({
        value: a.id,
        text: a.name
      }));
    }

    load();
  });

  async function search() {
    router.push({
      query: {
        q: listQuery.value.query
      }
    });
    listQuery.value.page = 1;
    load();
  }

  async function submit(row) {
    row.submitting = true;

    try {
      await Agreements.request({
        method: "put",
        url: `${row.id}/submit/`
      });
      Message.success("Agreement submitted for review");
      row.status = 2;
      row.statusText = statusDisplay(2);
      row.statusType = statusType(2);
    } catch (e) {
      Message({
        type: "error",
        message: toHtml(e)
      });
    } finally {
      row.submitting = false;
    }
  }

  function names(arr: Lookup[]) {
    return arr.map(o => o.name).join(", ");
  }

  function name(agreement: AgreementData) {
    if (agreement.name !== "") return agreement.name;
    let info = agreement.inventory.name;
    if (agreement.advertiser !== null) info = `${agreement.advertiser.name} - ${info}`;
    if (isAdmin) info = `${agreement.agency.name} - ${info}`;
    return info;
  }

  return {
    statuses,
    isAdmin,
    count,
    listQuery,
    loading,
    agreements,
    agencies,
    filterHandler,
    load,
    search,
    submit,
    names,
    name
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
