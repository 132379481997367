import { Component, Vue } from "vue-property-decorator"
import { ElForm } from "element-ui/types/form"

@Component
export default class FormMixin extends Vue {
	$refs!: {
		form: ElForm
	}

	protected form: any = {}

	protected isEdit = false
	protected submitting = false
}
