import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator"
import { AxiosPromise } from "axios"
import { Lookup } from "@/select/types"
import { UserModule } from "@/store/modules/user"

/* A demand-side selector; should be used by anything who's values are
 * filtered by agency
 */
@Component
export default class DemandSideSelectorMixin extends Vue {
	@Prop({ default: false }) protected multiple!: boolean
	@Prop() readonly value!: number | number[] | null
	@Prop() list: Lookup[] = []

	protected get isAdmin() {
		return UserModule.roles.includes("admin")
	}
}

/* A demand-side selector; should be used by anything who's values are
 * filtered by agency
 */
@Component
export class LazyDemandSideSelectorMixin extends Vue {
	@Prop({ default: false }) protected multiple!: boolean
	@Prop() readonly value!: number | number[] | null

	inheritAttrs = false

	list: Lookup[] = []
	loading = false

	protected get isAdmin() {
		return UserModule.roles.includes("admin")
	}
}
