import { FrequencyCapDuration, LineItemFrequencyCapDuration } from "@/select"
import { LocationData } from "@/select/types"
import { ListParams } from "./api/base"
import Generate from "./api/crud"

export interface Params extends ListParams {
	name?: string
}

export interface Agency {
	id?: number | null
	name: string
	serving_fee_included_in_cpm: boolean
	display_adserving_fee: number
	video_adserving_fee: number
	pricing_models: any[]
	active_days: number[]
	fixed_active: boolean
	percentage_active: boolean
	starting_hour: string
	ending_hour: string
	frequency_cap: number
	frequency_cap_duration: number
	line_item_frequency_cap: number
	line_item_frequency_cap_duration: number
	countries: LocationData[]
	display_brand_safe: boolean
	video_brand_safe: boolean
}

export interface AgencyForm extends Omit<Agency, "id"> {}
export interface AgencyData extends Agency {
	id: number
}

export const defaultAgencyData: Agency = {
	name: "",
	serving_fee_included_in_cpm: false,
	display_adserving_fee: 0,
	video_adserving_fee: 0,
	starting_hour: "00:00",
	ending_hour: "00:00",
	frequency_cap: 1,
	frequency_cap_duration: FrequencyCapDuration.PerHour,
	line_item_frequency_cap: 1,
	line_item_frequency_cap_duration: LineItemFrequencyCapDuration.PerHour,
	active_days: [],
	fixed_active: false,
	percentage_active: false,
	pricing_models: [],
	countries: [],
	display_brand_safe: false,
	video_brand_safe: false,
}

export default Generate<AgencyForm, AgencyData>("/agencies")
