import { Component, Vue } from "vue-property-decorator"
import { UserModule } from "@/store/modules/user"
export { default as FormMixin } from "./form"
export { default as ListMixin, defaultListQuery } from "./list"
export {
	default as DemandSideSelectorMixin,
	LazyDemandSideSelectorMixin,
} from "./selector"
export { mixins } from "vue-class-component"

@Component
export class CommonMixin extends Vue {
	protected get isAdmin() {
		return UserModule.roles.includes("admin")
	}

	protected get roles() {
		return UserModule.roles
	}
}

@Component
export class PageMixin extends CommonMixin {
	beforeRouteLeave(to, from, next) {
		this.$emit("routechange")
		next()
	}
}
